// eslint-disable-next-line no-undef
const theTime = l18n_js_counter_vars?.time;
const counterTime = document.querySelectorAll('.counter-time');

console.log('the time', theTime);
if (theTime) {
	const countDownDate = new Date(theTime).getTime();
	counterTime.forEach(function(item, index) {
		setTimeout(function() {
			item.classList.add('fade-in');
		}, 500 * index);
	});

	const x = setInterval(function() {
		const now = new Date().getTime();
		const distance = countDownDate - now;
		let days = Math.floor(distance / (1000 * 60 * 60 * 24));
		const weeks = Math.floor(days / 7);
		days = days % 7;
		const hours = Math.floor(
			(distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
		);
		const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
		// const seconds = Math.floor((distance % (1000 * 60)) / 1000);
		document.querySelector('[rel="weeks"]').textContent =
			weeks < 10 ? `${weeks}` : weeks;
		document.querySelector('[rel="days"]').textContent =
			days < 10 ? `0${days}` : days;
		document.querySelector('[rel="hours"]').textContent =
			hours < 10 ? `0${hours}` : hours;
		document.querySelector('[rel="minutes"]').textContent =
			minutes < 10 ? `0${minutes}` : minutes;
		// + "w " + days + "d " + hours + "h " + minutes + "m " + seconds + "s ";
		if (distance < 0) {
			clearInterval(x);
			const p = document.createElement('p');
			p.textContent = 'EXPIRED';
			document.querySelector('.counter-time-wrap').appendChild(p);
			counterTime.forEach(function(item, index) {
				setTimeout(function() {
					item.classList.remove('fade-in');
					item.classList.add('fade-out');
				}, 500 * index);
			});
		}
	}, 1000);
}
